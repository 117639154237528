import Http from './api';

export const getStartedEloquaRegister = async (postData) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/form/getstarted/${process.env.VUE_APP_GET_STARTED_FORM_ID}`;

  return Http.post(url, postData).then((response) => {
    return response.data;
  });
};

export const downloadArticleEloquaRegister = async (postData) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/form/downloadarticle`;

  return Http.post(url, postData).then((response) => {
    return response.data;
  });
};
